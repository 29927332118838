/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Lato+Sans:300,400,600,700,900&display=swap');
 */ 
 :root {
    --fontSG: Lato;
    --fontNunito:Nunito;
    --fontLato:Lato;
    --fontNunitosans:'Nunito Sans';
    --fontLato: Lato;
    --fontMont:Lato;
    --font-weightLight: 300;
    --font-weightRegular: 400;
    --font-weightSemiBold: 600;
    --font-weightBold: 700;
    --font-weightBlack: 900;

}
body.modal-open {
    overflow: hidden;
}
b, strong{
    font-family: var(--fontNunitosans);
    font-weight: var(--font-weightBold) !important;
}
.table>:not(:first-child){
    border-top: 0px solid !important;
}
.reading-block, .reading-label {
    margin-top: -10px !important;
}
.modal-content {
border-radius: 0px !important;
background-color: transparent !important;
border: 0px solid !important;
border-top-right-radius:0%;
border-bottom-right-radius:0%;
}
.custom-class{
    left: auto !important;
    right: 0px !important;
    max-width: 405px;
    bottom: 0px !important;
    margin-top: -30px !important;
    height: auto !important;

}
.modal-dialog{
    margin-bottom: 0px !important;
}
body,
html {
    font-family: var(--fontLato);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    height: 100%;
    background: #FAFAFA;
}
p{
    font-family: var(--fontLato);
    color: #484555 !important;
}
h6{
    font-family: var(--fontLato);
    font-weight: var(--font-weightRegular);
    font-size: 16px !important;
    color: #010036 !important;
}

app-root {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    display: block;
}
.logo{
        max-width: 154px;
    }

body:not(.keyboardUser) *:focus {
    outline: none;
}
.form-control:disabled, .form-control[readonly] {
    background: #FAFAFA !important;
    opacity: 1;
}
.is-invalid {
   border-color: #FD5860 !important;
    box-shadow:none;
    margin-bottom: 0px !important;
    border-width: 1px !important;
}

.errortxt {
    font-family: var(--fontLato);
    font-weight: var( --font-weightRegular);
    color: #FD5860 !important;
    font-size: 12px !important;
}
#colorcode{
    color: #fd5860 !important;
}
.invalid-feedback,
.text-danger {
    font-family: "Lato" !important;
font-size: 12px !important;
line-height: 18px;
margin:2px 0px !important;
text-align: left;
color: #fd5860 !important;
    font-weight: var( --font-weightRegular);
}

.disabled-btn {
    opacity: 25% !important;
}

.sg-font-m-l {
    font-family: var(--fontLato);
    font-weight: var( --font-weightRegular) !important;
}

.sg-font-m-r {
    font-family: var(--fontLato);
    font-weight: var( --font-weightRegular) !important;
}
.swiper-button-prev {
    background-image: url(https://assets.ooki.io/assetsImage/fav_left.svg) !important;
    width: 36px !important;
    height: 53px !important;
    background-size: 100px 100px !important;
}
.swiper-button-next{
    background-image: url(https://assets.ooki.io/assetsImage/fav_right.svg) !important;
    width: 36px !important;
    height: 53px !important;
    background-size: 100px 100px !important;
}
.sg-font-m-m {
    font-family: var(--fontLato);
    font-weight: var( --font-weightRegular) !important;
}

.sg-font-s-b {
    font-family: var(--fontLato);
    font-weight: var( --font-weightSemiBold) !important;
    /*600*/
}

.sg-font-m-b {
    font-family: var(--fontLato);
    font-weight: var( --font-weightSemiBold) !important;
    /*700*/
}

.sg-font-m-ex-b {
    font-family: var(--fontLato);
    font-weight: var( --font-weightSemiBold)!important;
    /*800*/
}


/*text color */

.sg-txt-steel-white {
    color: #ffffff !important;
}

.sg-txt-steel-blue {
    color: #2E4D71;
}

.sg-txt-silver {
    color: #999999;
}

.sg-txt-gry-silver {
    color: #666666;
}

.sg-txt-gry-silverb {
    color: #6B6B6B;
}

.sg-txt-green {
    color: #5db90a;
}

.sg-txt-orangee {
    color: #FF7043;
}

.sg-txt-gry {
    color: #E7E7E4;
}

.sg-txt-light-black {
    color: #494d52;
}

.sg-txt-dark-black {
    color: #272b35
}

.sg-txt-red {
    color: #b90a0a;
}

.sg-txt-orange {
    color: #EE9A07;
}

.sg-txt-grey {
    color: #2E4D71;
}

.sg-txt-lgrey {
    color: #999999;
}

.sg-bg-sky-dblue {
    color: #4DB1F9;
}

.sg-bg-sky-de {
    color: #bebebe;
}

.sg-bg-sky-darkk {
    color: #333333;
}


/* background color */

.sg-bg-green {
    background-color: #5db90a;
}

.sg-bg-sky {
    background-color: #56A9FD;
}

.sg-bg-dark-blue {
    color: #2E4D71;
}

.sg-bg-dark-m-grey {
    color: #6b6b6b;
}

.theme-green .bs-datepicker-head {
    background-color: #50A8FC !important;
}
/* font size */

.sg-font-size-10 {
    font-size: 10px !important;
}

.sg-font-size-11 {
    font-size: 11px !important;
}

.sg-font-size-12 {
    font-size: 12px !important;
}

.sg-font-size-13 {
    font-size: 13px !important;
}

.sg-font-size-14 {
    font-size: 14px !important;
}

.sg-font-size-15 {
    font-size: 15px !important;
}

.sg-font-size-16 {
    font-size: 16px !important;
}

.sg-font-size-17 {
    font-size: 17px !important;
}

.sg-font-size-18 {
    font-size: 18px !important;
}

.sg-font-size-20 {
    font-size: 20px !important;
}

.sg-font-size-22 {
    font-size: 22px !important;
}

.sg-font-size-24 {
    font-size: 24px !important;
}

.sg-font-size-26 {
    font-size: 26px !important;
}

.sg-font-size-30 {
    font-size: 30px !important;
}

.sg-font-size-33 {
    font-size: 33px !important;
}

.sg-font-size-35 {
    font-size: 35px !important;
}


/*End latest UI kit css */


/* container */

.sg-container {
    max-width: 1920px;
    height: auto;
    margin: 0 auto;
}

.sg-container-shadow {
    box-shadow: 0 0 14px #e6e6e6;
}

.sg-container-bottom-green {
    border-bottom: 5px #BEE418 solid;
}

@media (max-width: 991px) {
    .sg-container-shadow {
        box-shadow: none;
    }
    .reading-block, .reading-label {
        margin-top: -8px !important;
        margin-left: 3px;
    }
    .swiper-button-prev {
        background-image: none !important;
    }
    .swiper-button-next {
        background-image: none !important;
    }
    .sg-container-bottom-green {
        border: 0;
    }
    .wrapper-footer-sitky {
        padding-top: 90px !important;
    }
}
.multiselect-dropdown .dropdown-btn{
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC !important;
    border-radius: 8px !important;
    padding: 8px 15px !important;
    }
.selected-item:hover{
    box-shadow: 0px 0px transparent !important;
}
    .selected-item{
        font-family: 'Nunito';
        font-style: normal;
        margin-right: 7px !important;
        font-weight: 700;
        margin-bottom: 0px !important;
        font-size: 10px !important;
        line-height: 14px !important;
        color: #333333 !important;
        background: #FAFAFA !important;
border: 1px solid #1DBF84 !important;
border-radius: 5px !important;
padding: 6px 5px 3px !important;
    }
.dropdown-multiselect__caret{
        transform: rotate(45deg) !important;
        -webkit-transform: rotate(45deg);
        height: 40px !important;
    }
    .dropdown-list{
        margin-top: 0px !important;
        background: #FFFFFF;
        box-shadow: 0px 10px 30px 10px rgba(112, 144, 176, 0.12) !important;
        border-radius: 15px !important;
        padding-bottom:10px !important;
        padding-top: 10px !important;
        
    }
    .multiselect-item-checkbox input[type=checkbox] + div:before{
        background: #FAFAFA !important;
border: 1px solid #1DBF84 !important;
border-radius: 5px !important;
width:20px !important;
height:20px !important;
    }
    .multiselect-item-checkbox input[type=checkbox]+ div{
        font-family: 'Nunito' !important;
font-style: normal;
font-weight: 400;
font-size: 14px !important;
line-height: 22px;
padding-left: 35px !important;
color: #333333 !important;
    }
    .multiselect-item-checkbox input[type=checkbox] + div:after{
        border-color: #1DBF84 !important;
        top: 50% !important;
    left: 5px !important;
    width: 9px !important;
    height: 4px !important;
    margin-top: -3px !important;
    }
 .dropdown-multiselect__caret:before {
        position: relative;
        right: 0;
        margin-top: 4px;
        top: 12% !important;
        border-style: solid;
        border-width: 8px 8px 0;
        content: "";
        border-width: 0 2px 2px 0 !important;
        border-color: #999 !important;
        display: inline-block;
        padding: 3px;
    }
    .selected-item a{
        color: #999999 !important;
        padding-left: 8px !important;
    }
:focus {
    outline: none;
}

select:focus,
button:focus {
    outline: none;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cs-danger-mark {
    color: #D69885 !important;
}

.active-sort-col {
    color: #5DB90A !important;
    position: absolute;
    z-index: 1;
    background: #ffffff;
    top: 1px;
    height: 20px;
}

.disable-select {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}
input{
    outline: none;
    box-shadow: none;
}
input:focus{
    outline: none !important;
    box-shadow: none !important;
}
.form-control:focus{
    outline: none !important;
    box-shadow: none !important;

}
/* .custome-select {
    padding: 7px 30px 7px 10px !important;
} */

.key-details-btn:hover {
    box-shadow: 0 0 4px 0px #56A9FD;
}

.view-details-btn:hover {
    background-color: #218cf8 !important;
    color: #fff !important;
    box-shadow: 0 0 4px 0px #1b8af8 !important;
}

#btn-get-quote:hover {
    background-color: #218cf8 !important;
    color: #ffffff;
    border: 0 solid #56A9FD;
    box-shadow: 0 0 3px #56A9FD;
}

.btn-apply:hover {
    background-color: #218cf8 !important;
}

.btn-key-fact:hover {
    background-color: #56A9FD !important;
    color: #fff !important;
    border: 2px solid #56A9FD;
}

#btn-conf:hover {
    background-color: #218cf8 !important;
}

.nav-link:hover {
    box-shadow: 0 0 3px #b1b1b1 !important;
}

.cba:hover {
    background-color: #218cf8 !important;
    filter: drop-shadow(1px 4px 8px #9AC9FA);
    cursor: pointer !important;
}

.disputebutton:hover {
    background: #cfcfcf !important;
}
.saveinfobuttonn:hover {
    background: #5db90a !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}

.saveinfobutton:hover {
    background-color: #4ca000 !important;
}



#btn-proceed-next-step:hover {
    background-color: #4ca000 !important;
}

#btn-try-again:hover {
    background-color: #4ca000 !important;
}

#app-sign-up-identity-check-identification-failed:hover {
    background-color: #4ca000 !important;
}

#loaderimg {
    display: block;
    width: 100%;
    height: 100vh;
    text-align: center;
}

#loaderimg img {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

#loaderimg p {
    margin: 0 auto;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0 !important;
    cursor: auto;
    pointer-events: none;
}

.wrapper-footer-sitky {
    min-height: 100%;
    height: auto !important;
    margin: 0 auto -77px;
    padding-top: 95px;
    background: #FDFDFD;
}

.footer,
.dff {
    height:115px;
}
::-webkit-input-placeholder { /* Edge */
    color: #999 !important;
  }
  
  :-ms-input-placeholder { /* Internet Explorer */
    color: #999 !important;
  }
  
  ::placeholder {
    color: #999 !important;
  }
.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 11px !important;
    border-radius: 50%;
    background: white;
    letter-spacing: 0;
    color: darkgrey;
    padding-left: 12.5px;
    filter: drop-shadow(0 10px 20px rgba(153, 153, 153, .2));
    padding-top: 9px;
    width: 30px!important;
    height: 28px!important;
    line-height: 1;
    text-transform: none !important;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background: none !important;
    color: #999 !important;
    margin-bottom: 0px;
}
.custome-select{  
    margin-bottom: 20px;
    font-family: var(--fontLato);
    font-weight: var(--font-weightRegular);
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #484555 !important;
    width:100%;
    border: 1px solid #A5A5A5;
    border-radius: 5px;
    padding: 10px 22px 10px 15px;
    cursor: pointer;
    background: url('https://assets.ooki.io/assetsImage/dd_icon.svg') no-repeat 95% !important;
}

@media(max-width:576px){
    .custome-select{ 
        background: url('https://assets.ooki.io/assetsImage/dd_icon.svg') no-repeat 90% !important;
        margin-bottom: 10px;
    }  
  
}
@media(max-width:991px){
.form-control{
    color: #484555 !important;
    font-size:16px !important;
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 0px !important;
    border-radius: 0%;
    background: transparent;
    letter-spacing: 0;
    color: darkgrey;
    padding-left: 0px;
    filter: none;
    padding-top: 0px;
    width: 0px!important;
    height: 0px!important;
    line-height: 1;
    text-transform: none !important;
}
}
select::-ms-expand {
    display: none;
}
select {
    -webkit-appearance: none;
    appearance: none;
    padding-right: 26px !important;
}
#select-box{
    padding-right:8px !important;
}
@-moz-document url-prefix() {
    .ui-select {
        border: 1px solid #CCC;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
    }
    .ui-select select {
        width: 110%;
        background-position: right 30px center !important;
        border: none !important;
    }
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
    background-color: #50A8FC !important;
}
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
    border-radius: 50%;
    width: 35px !important;
    height: 35px !important;
}
.invalid{
    color:red;
      }